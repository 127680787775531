/*
 * ꏸ꒓ꐇꁕꍟꃃꆂ꒓ꁒꍟ
 * ꏸ꒓ꐇꁕꍟꃃꆂ꒓ꁒꍟ
 * ꏸ꒓ꐇꁕꍟꃃꆂ꒓ꁒꍟ
 * ꏸ꒓ꐇꁕꍟꃃꆂ꒓ꁒꍟ
 *
 *
 *
 *
 * ρ(௶Ø†ξ) ∺ 爪(ϒ६∑ナ)/∰(￥Ꭿ₸ໂ)
 */

// Imports:
import * as React from 'react';

import './HomeDesktop_V3.css';
import './HomeMobile_V3.css';

import sunnyVideo from '../Resources/Crude-Borne-Header-EXTENDED-Option.mp4';
import backgroundVideo from '../Resources/Crude-Borne-Header-NIGHT-EXTENDED.mp4';
import caveVideo from '../Resources/CaveOfRevelations-HQ-Render.mp4';
import startingOverlayPortal from '../Resources/White-Starting-Overlay-Portal.mp4';
import mintProcessingMap from '../Resources/Mint-Processing-Map.mp4';
// import siteAudio from '../Resources/CrudeBorne-Audio-Background.mpeg';
// import siteAudio from '../Resources/CrudeBorne-Cave-Audio.mpeg';
// import siteAudio from '../Resources/Background-Audio-5Ingredients.mpeg';
// import siteAudio from '../Resources/ES_Stepping Into Sunlight - Daniel Fridell.mp3';
// import siteAudio from '../Resources/CrudeLings_Site_Audio.mpeg';
// import siteAudio from '../Resources/PikanikBaskitzzAudio.mpeg';
import siteAudio from '../Resources/BG_Portal_Sound_Anniversary.mp3';
import sunnyAudio from '../Resources/Sunny_Audio.mp3';
import caveAudio from '../Resources/Cave_Audio.mp3';
// import mintButton from '../Resources/Images/Mint-Button.png';
import mintButton from '../Resources/Images/ClaimSpermButton.png';
import twitterButton from '../Resources/Images/Twitter-Button.png';
import openseaButton from '../Resources/Images/Opensea-Button.png';
import etherscanButton from '../Resources/Images/Etherscan-Button.png';
import compassPlain from '../Resources/Images/CompassButton.png';
import crystalBall from '../Resources/Images/CrystalBall.png';
import crystalBallWhite from '../Resources/Images/CrystalBall_White.png';
import crystalBallGreen from '../Resources/Images/CrystalBall_Green.png';
import crystalBallPurple from '../Resources/Images/CrystalBall_Purple.png';
import crystalBallOff from '../Resources/Images/CrystalBallOff.png';
import crystalBallOn from '../Resources/Images/CrystalBallOn.png';
import crystalBallCrudeLings from '../Resources/Images/CrystalBall_CrudeLings.png';
import crystalBallAqua from '../Resources/Images/CrystalBall_Aquamarine.png';
import greenGlowBall from '../Resources/Images/GreenGlow.png';
import purpleGlowBall from '../Resources/Images/PurpleGlow.png';
import whiteGlowBall from '../Resources/Images/WhiteGlow.png';
import offGlowBall from '../Resources/Images/OffGlow.png';
import onGlowBall from '../Resources/Images/OnGlow.png';
import crudelingsGlowBall from '../Resources/Images/CrudeLingsGlow.png';
import aquaGlowBall from '../Resources/Images/AquamarineGlow.png';
import mapAnimationHQ from '../Resources/MapAnimation-HQ.mp4';
import etherscanNew from '../Resources/Images/EtherscanNew.png';
import twitterNew from '../Resources/Images/TwitterNew.png';
import openSeaNew from '../Resources/Images/OpenseaNew.png';

import { isMobile } from '../Helpers/DeviceDetect';

import {
    EGGS_ADDRESS,
    JELLY_ADDRESS,
    BOTTLE_ADDRESS,
    FIRST_5_ADDRESS,
    OFF_SWITCH_ADDRESS,
    ON_SWITCH_ADDRESS,
    CRUDELINGS_ADDRESS,
    SECOND_5_ADDRESS,
    DEPLOY_MODE,
} from '../Helpers/Config';
import eggsABI from '../EggsABI.json';
import jellyABI from '../JellyABI.json';
// import bottleABI from '../BottleABI.json';
import bottleABI from '../BottleABIDev.json';
import first5ABI from '../First5ABI.json';
import offSwitchABI from '../OffSwitchABI.json';
import onSwitchABI from '../OnSwitchABI.json';
import crudelingsABI from '../CrudeLingsABI.json';
import second5ABI from '../Second5ABI.json';

import eggTalk from '../eggTalk0.json';

import {ethers} from "ethers";

const entryTextSequence = [
    // 'You and your compatriots huddle closely together as you approach the threshold...',
    // '... Strange... as you approach, the portal seems to pull you in...',
    // '... You feel a sense of energy rising...',
    // 'Suddenly... all around you, your fellows and their shells begin to strain...',
    // '!!HEXEGGS!! One cries!',
    // 'A Rally Call... to shield oneself against those that would seek to stop us...',
    '',
];

const entryAfterTextSequence = [
    // 'Is this... HyperBorea?',
    '',
];

export default class Home extends React.PureComponent {
    state = {
        showPopup: false,
        popupOpacity: 0,
        showStartingOverlay: true,
        startingOverlayOpacity: 1,
        canContinueToMain: 0,
        mintMinusPressed: false,
        mintPlusPressed: false,
        numToMint: 0,
        selectedAddress: null,
        cbeContract: null,
        cbjContract: null,
        bottleContract: null,
        first5Contract: null,
        offSwitchContract: null,
        onSwitchContract: null,
        crudelingsContract: null,
        second5Contract: null,
        jellyMintStatus: 0,
        jellyTotalSupply: 0,
        bottleMintStatus: 0,
        bottleTotalSupply: 0,
        first5MintStatus: 0,
        first5TotalSupply: 0,
        offSwitchMintStatus: 0,
        offSwitchTotalSupply: 0,
        onSwitchMintStatus: 0,
        onSwitchTotalSupply: 0,
        crudelingsMintStatus: 0,
        crudelingsTotalSupply: 0,
        second5MintStatus: 0,
        second5TotalSupply: 0,
        giftReceiver: '',
        giftReceiverIsValid: false,
        isConfirmingMint: false,
        loadingPhraseCounter: 0,
        curLoadingPhrases: [],
        currentEggsValidJelly: '{}',
        currentEggsValidBottle: '{}',
        currentEggsValidFirst5: '{}',
        currentEggsValidOff: '{}',
        currentEggsValidOn: '{}',
        currentEggsValidCrudeLings: '{}',
        currentEggsValidSecond5: '{}',
        eggsLoading: false,
        numDots: 1,
        waitingText: 'waitaminnit.',
        dotsTimeout: null,
        entryTextIndex: 0,
        entryBeingAnimated: false,
        entryAfterTextIndex: 0,
        entryAfterBeingAnimated: false,
        etcBgOpacity: 0,
        contractSelection: 0,
        contractSelectorOpen: false,
        isMapOpen: false,
        mapPopupOpacity: 0,
        mintIndexMap: {},
        connectShowing: false,
        eggsBalance: 0,
        pathByEggTalk: 0
    }

    componentDidMount() {
        setInterval(() => {
            this.setState((state) => {
                let newNumDots;
                let newWaitingText;
                if (state.numDots === 1) {
                    newNumDots = 2;
                    newWaitingText = 'waitaminnit..';
                }
                else if (state.numDots === 2) {
                    newNumDots = 3;
                    newWaitingText = 'waitaminnit.';
                }
                else {
                    newNumDots = 1;
                    newWaitingText = 'waitaminnit...';
                }

                return ({
                    numDots: newNumDots,
                    waitingText: newWaitingText,
                });
            });
        }, 1000);

        document.getElementById('backgroundVideo').oncanplay = this.allowContinueToMain;
        document.getElementById('backgroundAudio').oncanplay = this.allowContinueToMain;
    }

    componentWillUnmount() {
        clearInterval(this.state.dotsTimeout);
    }

    retrieveJellyTotalSupply = () => {
        setTimeout(async () => {
            this.setState({
                jellyTotalSupply: await this.state.cbjContract.totalSupply(),
            }, () => {
                this.retrieveJellyTotalSupply();
            });
        },1000);
    }

    retrieveBottleTotalSupply = () => {
        setTimeout(async () => {
            this.setState({
                bottleTotalSupply: await this.state.bottleContract.totalSupply(),
            }, () => {
                this.retrieveBottleTotalSupply();
            });
        },1000);
    }

    retrieveFirst5NumClaimed = () => {
        setTimeout(async () => {
            this.setState({
                first5TotalSupply: await this.state.first5Contract.howMuchSoFar(),
            }, () => {
                this.retrieveFirst5NumClaimed();
            });
        },1000);
    }

    retrieveOffSwitchTotalSupply = () => {
        setTimeout(async () => {
            this.setState({
                offSwitchTotalSupply: await this.state.offSwitchContract.totalSupply(),
            }, () => {
                this.retrieveOffSwitchTotalSupply();
            });
        },1000);
    }

    retrieveOnSwitchTotalSupply = () => {
        setTimeout(async () => {
            this.setState({
                onSwitchTotalSupply: await this.state.onSwitchContract.totalSupply(),
            }, () => {
                this.retrieveOnSwitchTotalSupply();
            });
        },1000);
    }

    retrieveCrudeLingsTotalSupply = () => {
        setTimeout(async () => {
            this.setState({
                crudelingsTotalSupply: await this.state.crudelingsContract.totalSupply(),
            }, () => {
                this.retrieveCrudeLingsTotalSupply();
            });
        },1000);
    }

    retrieveSecond5NumClaimed = () => {
        setTimeout(async () => {
            this.setState({
                first5TotalSupply: await this.state.second5Contract.howMuchSoFar(),
            }, () => {
                this.retrieveSecond5NumClaimed();
            });
        },1000);
    }

    animateEntryText = () => {
        setTimeout(async () => {
            this.setState((state) => {
                return ({
                    entryTextIndex: state.entryTextIndex + 1,
                });
            }, () => {
                if (this.state.entryTextIndex !== (entryTextSequence.length - 1)) {
                    this.animateEntryText();
                }
            });
        }, 5000);
    }

    animateEntryAfterText = () => {
        setTimeout(async () => {
            this.setState((state) => {
                return ({
                    entryAfterTextIndex: state.entryAfterTextIndex + 1,
                });
            }, () => {
                if (this.state.entryAfterTextIndex !== (entryAfterTextSequence.length - 1)) {
                    this.animateEntryAfterText();
                }
            });
        // }, 5000);
        }, 0);
    }

    animateEntry = () => {
        this.setState({
            entryBeingAnimated: true,
        }, async () => {
            this.animateEntryText();
            const audioElement = document.getElementById('backgroundAudio');
            audioElement.pause();
            audioElement.currentTime = 0;
            audioElement.play();
            setTimeout(() => {
                this.setState({
                    startingOverlayOpacity: 0,
                    etcBgOpacity: 1,
                }, () => {
                    // this.playBackgroundVideo();

                    setTimeout(() => {
                        this.setState({
                            showStartingOverlay: false,
                            etcBgOpacity: 0,
                        }, () => {
                            this.playBackgroundVideo();
                            setTimeout(async () => {
                                console.log('Animate After Entry');
                                this.setState({
                                    entryAfterBeingAnimated: true,
                                }, () => {
                                    this.animateEntryAfterText();
                                    setTimeout(() => {
                                        this.setState({
                                            entryBeingAnimated: false,
                                            entryAfterBeingAnimated: false,
                                        });
                                    }, 5000);
                                });
                            }, 5000);
                        });
                    }, 5000);
                });
            },0);
        });
    }

    sleep = (ms) => { // (glorious sleep)
        return (new Promise(resolve => setTimeout(resolve, ms)));
    }

    playVideo = (e) => {
        e.target.play();
    }

    playBackgroundVideo = () => {
        const videoElement = document.getElementById('backgroundVideo');
        videoElement.play();
        // const audioElement = document.getElementById('backgroundAudio');
        // audioElement.play();
    }

    playLoadingMap = () => {
        const mapElement = document.getElementById('inProgressVideo');
        mapElement.play();
    }

    stopLoadingMap = () => {
        const mapElement = document.getElementById('inProgressVideo');
        mapElement.pause();
        mapElement.currentTime = 0;
        mapElement.load();
    }

    playHQMap = () => {
        const mapElement = document.getElementById('mapAnimationHQ');
        mapElement.play();
    }

    stopHQMap = () => {
        const mapElement = document.getElementById('mapAnimationHQ');
        mapElement.pause();
        mapElement.currentTime = 0;
        mapElement.load();
    }

    // continueToMain = () => {
    //     if (this.state.canContinueToMain === 2) {
    //         this.setState({
    //             startingOverlayOpacity: 0,
    //         }, () => {
    //             this.playBackgroundVideo();
    //
    //             setTimeout(() => {
    //                 this.setState({
    //                     showStartingOverlay: false,
    //                 });
    //             }, 2000);
    //         });
    //     }
    // }

    continueToMain = () => {
        this.animateEntry();
    }

    allowContinueToMain = () => {
        console.log('👁👁👁👁👁👁👁👁👁👁');
        console.log('👁 Crude. 👁 Borne. 👁 Crude. 👁 Borne. 👁 Crude. 👁 Borne. 👁 Crude. 👁 Borne. 👁');
        if (this.state.canContinueToMain === 1) {
            setTimeout(() => {
                this.setState((state) => {
                    return ({
                        canContinueToMain: state.canContinueToMain + 1,
                    });
                });
            }, 5000);
        }
        else {
            this.setState((state) => {
                return ({
                    canContinueToMain: state.canContinueToMain + 1,
                });
            });
        }
    }

    openMintPopup = () => {
        this.setState({
            showPopup: true,
        }, () => {
            this.setState({
                popupOpacity: 1,
            });
        });
    }

    closeMintPopup = (e) => {
        if (e.target !== e.currentTarget) {
            return;
        }

        this.setState({
            popupOpacity: 0,
        }, () => {
            setTimeout(() => {
                this.setState({
                    showPopup: false,
                }, () => {
                    this.clearContractSelection();
                });
            }, 500);
        });
    }

    handleNFTTransfer = (from, to, tokenId) => {
        this.setState(async (state) => {
            const newEggsValidJelly = JSON.parse(state.currentEggsValidJelly);
            const newEggsValidBottle = JSON.parse(state.currentEggsValidBottle);
            const newEggsValidFirst5 = JSON.parse(state.currentEggsValidFirst5);
            const newEggsValidOff = JSON.parse(state.currentEggsValidOff);
            const newEggsValidOn = JSON.parse(state.currentEggsValidOn);
            const newEggsValidCrudeLings = JSON.parse(state.currentEggsValidCrudeLings);
            const newEggsValidSecond5 = JSON.parse(state.currentEggsValidSecond5);

            if (from === this.state.selectedAddress) {
                newEggsValidJelly[tokenId] = false;
                newEggsValidBottle[tokenId] = false;
                newEggsValidFirst5[tokenId] = false;
                newEggsValidOff[tokenId] = false;
                newEggsValidOn[tokenId] = false;
                newEggsValidCrudeLings[tokenId] = false;
                newEggsValidSecond5[tokenId] = false;
            }
            if (to === this.state.selectedAddress) {
                newEggsValidJelly[tokenId] = !(await this.state.cbjContract.hazCompanion(tokenId));
                newEggsValidBottle[tokenId] = !(await this.state.bottleContract.eliksirHazBeenGot(tokenId));
                newEggsValidFirst5[tokenId] = !(await this.state.first5Contract.harambeIsStillAlive(tokenId));
                newEggsValidOff[tokenId] = !(await this.state.offSwitchContract.sirkutIzPrinted(tokenId));
                newEggsValidOn[tokenId] = !(await this.state.onSwitchContract.sirkutIzPrinted(tokenId));
                newEggsValidCrudeLings[tokenId] = !(await this.state.crudelingsContract.longpeepeeSyndrome(tokenId));
                newEggsValidSecond5[tokenId] = !(await this.state.second5Contract.piknikInvitayshun(tokenId));
            }

            return ({
                currentEggsValidJelly: newEggsValidJelly,
                currentEggsValidBottle: newEggsValidBottle,
                currentEggsValidFirst5: newEggsValidFirst5,
                currentEggsValidOff: newEggsValidOff,
                currentEggsValidOn: newEggsValidOn,
                currentEggsValidCrudeLings: newEggsValidCrudeLings,
                currentEggsValidSecond5: newEggsValidSecond5,
            });
        });
    }

    connectWallet = async () => {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);

        const cbeContract = new ethers.Contract(
            EGGS_ADDRESS,
            eggsABI,
            provider.getSigner()
        );

        const cbjContract = new ethers.Contract(
            JELLY_ADDRESS,
            jellyABI,
            provider.getSigner()
        );
        cbeContract.on('Transfer', this.handleNFTTransfer);

        const bottleContract = new ethers.Contract(
            BOTTLE_ADDRESS,
            bottleABI,
            provider.getSigner()
        );

        const first5Contract = new ethers.Contract(
            FIRST_5_ADDRESS,
            first5ABI,
            provider.getSigner()
        );

        const offSwitchContract = new ethers.Contract(
            OFF_SWITCH_ADDRESS,
            offSwitchABI,
            provider.getSigner()
        );

        const onSwitchContract = new ethers.Contract(
            ON_SWITCH_ADDRESS,
            onSwitchABI,
            provider.getSigner()
        );

        const crudelingsContract = new ethers.Contract(
            CRUDELINGS_ADDRESS,
            crudelingsABI,
            provider.getSigner()
        );

        const second5Contract = new ethers.Contract(
            SECOND_5_ADDRESS,
            second5ABI,
            provider.getSigner()
        );

        this.setState({
            selectedAddress: accounts[0],
            cbeContract: cbeContract,
            cbjContract: cbjContract,
            bottleContract: bottleContract,
            first5Contract: first5Contract,
            offSwitchContract: offSwitchContract,
            onSwitchContract: onSwitchContract,
            crudelingsContract: crudelingsContract,
            second5Contract: second5Contract,
        }, async () => {
            let jellyMintStatus;
            let jellyTotalSupply;
            let bottleMintStatus;
            let bottleTotalSupply;
            let first5MintStatus;
            let first5TotalSupply;
            let offSwitchMintStatus;
            let offSwitchTotalSupply;
            let onSwitchMintStatus;
            let onSwitchTotalSupply;
            let crudelingsMintStatus;
            let crudelingsTotalSupply;
            let second5MintStatus;
            let second5TotalSupply;

            try {
                jellyMintStatus = await this.state.cbjContract.wen();
                jellyTotalSupply = (await this.state.cbjContract.totalSupply()).toNumber();
            }
            catch (err) {
                console.log(err);
            }

            try {
                bottleMintStatus = await this.state.bottleContract.canUSeesIt();
                bottleTotalSupply = (await this.state.bottleContract.totalSupply()).toNumber();
            }
            catch (err) {
                console.log(err);
            }

            try {
                first5MintStatus = await this.state.first5Contract.spotInJurKnee();
                first5TotalSupply = (await this.state.first5Contract.howMuchSoFar()).toNumber();
            }
            catch (err) {
                console.log(err);
            }

            try {
                offSwitchMintStatus = await this.state.offSwitchContract.factoryMode();
                offSwitchTotalSupply = (await this.state.offSwitchContract.totalSupply()).toNumber();
            }
            catch (err) {
                console.log(err);
            }

            try {
                onSwitchMintStatus = await this.state.onSwitchContract.factoryMode();
                onSwitchTotalSupply = (await this.state.onSwitchContract.totalSupply()).toNumber();
            }
            catch (err) {
                console.log(err);
            }

            try {
                crudelingsMintStatus = await this.state.crudelingsContract.medyuluuk();
                crudelingsTotalSupply = (await this.state.crudelingsContract.totalSupply()).toNumber();
            }
            catch (err) {
                console.log(err);
            }

            try {
                second5MintStatus = await this.state.second5Contract.wenItzRedi();
                second5TotalSupply = (await this.state.second5Contract.ordurzTakenn()).toNumber();
            }
            catch (err) {
                console.log(err);
            }

            let eggsBalance;
            try {
                eggsBalance = await this.state.cbeContract.balanceOf(this.state.selectedAddress);
            }
            catch (err) {
                console.log(err);
            }

            this.retrieveJellyTotalSupply();
            this.retrieveBottleTotalSupply();
            this.retrieveFirst5NumClaimed();
            this.retrieveOffSwitchTotalSupply();
            this.retrieveOnSwitchTotalSupply();
            this.retrieveCrudeLingsTotalSupply();
            this.retrieveSecond5NumClaimed();
            // console.log(jellyMintStatus, jellyTotalSupply);
            this.setState({
                jellyMintStatus: jellyMintStatus,
                jellyTotalSupply: jellyTotalSupply,
                bottleMintStatus: bottleMintStatus,
                bottleTotalSupply: bottleTotalSupply,
                first5MintStatus: first5MintStatus,
                first5TotalSupply: first5TotalSupply,
                offSwitchMintStatus: offSwitchMintStatus,
                offSwitchTotalSupply: offSwitchTotalSupply,
                onSwitchMintStatus: onSwitchMintStatus,
                onSwitchTotalSupply: onSwitchTotalSupply,
                crudelingsMintStatus: crudelingsMintStatus,
                crudelingsTotalSupply: crudelingsTotalSupply,
                second5MintStatus: second5MintStatus,
                second5TotalSupply: second5TotalSupply,
                eggsBalance: eggsBalance,
            }, async () => {
                console.log('Loading eggs...');
                this.setPathByEggTalk();
                await this.loadEggs();
            });
        });
    }

    loadEggs = () => {
        this.setState({
            eggsLoading: true,
        }, async () => {
            const eggsBalance = await this.state.cbeContract.balanceOf(this.state.selectedAddress);
            // console.log(eggsBalance);

            // this.setState(async (state) => {
            //     const newValidEggs = JSON.parse(state.currentEggsValidJelly);
            //
            //     for (let i = eggsBalance - 1; i >= 0; i--) {
            //         console.log(i);
            //         try {
            //             const eggId = await state.cbeContract.tokenOfOwnerByIndex(this.state.selectedAddress, i);
            //             console.log('Egg: ' + eggId);
            //
            //             newValidEggs[eggId] = !(await state.cbjContract.hazCompanion(eggId));
            //
            //             console.log(newValidEggs);
            //         }
            //         catch (err) {
            //             console.log(err);
            //         }
            //     }
            //
            //     return ({
            //         currentEggsValidJelly: JSON.stringify(newValidEggs),
            //         eggsLoading: false,
            //     });
            // }, () => {
            //     console.log(this.state.currentEggsValidJelly);
            // });

            const newValidEggsJelly = JSON.parse(this.state.currentEggsValidJelly);
            const newValidEggsBottle = JSON.parse(this.state.currentEggsValidBottle);
            const newValidEggsFirst5 = JSON.parse(this.state.currentEggsValidFirst5);
            const newValidEggsOff = JSON.parse(this.state.currentEggsValidOff);
            const newValidEggsOn = JSON.parse(this.state.currentEggsValidOn);
            const newValidEggsCrudeLings = JSON.parse(this.state.currentEggsValidCrudeLings);
            const newValidEggsSecond5 = JSON.parse(this.state.currentEggsValidSecond5);

            for (let i = eggsBalance - 1; i >= 0; i--) {
                console.log(i);
                try {
                    const eggId = await this.state.cbeContract.tokenOfOwnerByIndex(this.state.selectedAddress, i);
                    console.log('Egg: ' + eggId);

                    newValidEggsJelly[eggId] = !(await this.state.cbjContract.hazCompanion(eggId));
                    newValidEggsBottle[eggId] = !(await this.state.bottleContract.eliksirHazBeenGot(eggId));
                    newValidEggsFirst5[eggId] = !(await this.state.first5Contract.harambeIsStillAlive(eggId));
                    newValidEggsOff[eggId] = !(await this.state.offSwitchContract.sirkutIzPrinted(eggId));
                    newValidEggsOn[eggId] = !(await this.state.onSwitchContract.sirkutIzPrinted(eggId));
                    newValidEggsCrudeLings[eggId] = !(await this.state.crudelingsContract.longpeepeeSyndrome(eggId));
                    newValidEggsSecond5[eggId] = !(await this.state.second5Contract.piknikInvitayshun(eggId));

                    console.log(newValidEggsJelly);
                    console.log(newValidEggsBottle);
                    console.log(newValidEggsFirst5);
                    console.log(newValidEggsOff);
                    console.log(newValidEggsOn);
                    console.log(newValidEggsCrudeLings);
                    console.log(newValidEggsSecond5);
                }
                catch (err) {
                    console.log(err);
                }
            }

            this.setState({
                currentEggsValidJelly: JSON.stringify(newValidEggsJelly),
                currentEggsValidBottle: JSON.stringify(newValidEggsBottle),
                currentEggsValidFirst5: JSON.stringify(newValidEggsFirst5),
                currentEggsValidOff: JSON.stringify(newValidEggsOff),
                currentEggsValidOn: JSON.stringify(newValidEggsOn),
                currentEggsValidCrudeLings: JSON.stringify(newValidEggsCrudeLings),
                currentEggsValidSecond5: JSON.stringify(newValidEggsSecond5),
                eggsLoading: false,
                eggsBalance: eggsBalance,
            }, () => {
                // console.log(this.state.currentEggsValidJelly);
                // console.log(this.state.currentEggsValidBottle);
                // console.log(this.state.currentEggsValidFirst5);
                // console.log(this.state.currentEggsValidOff);
                // console.log(this.state.currentEggsValidOn);
            });
        });
    }

    computeValid = () => {
        if (this.state.contractSelection === 0) {
            return {};
        }

        const validOptions = [
            this.state.currentEggsValidJelly,
            this.state.currentEggsValidBottle,
            this.state.currentEggsValidFirst5,
            this.state.currentEggsValidOff,
            this.state.currentEggsValidOn,
            this.state.currentEggsValidCrudeLings,
            this.state.currentEggsValidSecond5,
        ];

        console.log(validOptions);
        // const validEggs = this.state.contractSelection === 1 ?
        //     JSON.parse(this.state.currentEggsValidJelly) : (
        //         this.state.contractSelection === 2 ?
        //             JSON.parse(this.state.currentEggsValidBottle) :
        //             JSON.parse(this.state.currentEggsValidFirst5)
        //     );

        const validEggs = JSON.parse(validOptions[this.state.contractSelection - 1]);

        console.log(validEggs);
        const valid = [];
        for (let eggId of Object.keys(validEggs).sort()) {
            if (validEggs[eggId]) {
                valid.push(eggId);
            }
        }
        return valid;
    }

    retrieve = () => {
        const valid = this.computeValid();

        console.log('👁');

        if (this.state.contractSelection === 1) {
            if (this.state.jellyMintStatus !== 1 || valid.length === 0) {
                return;
            }
        }
        else if (this.state.contractSelection === 2) {
            if (this.state.bottleMintStatus !== 1 || valid.length === 0) {
                return;
            }
        }
        else if (this.state.contractSelection === 3) {
            if (this.state.first5MintStatus !== 1 || valid.length === 0) {
                return;
            }
        }
        else if (this.state.contractSelection === 4) {
            if (this.state.offSwitchMintStatus !== 1 || valid.length === 0) {
                return;
            }
        }
        else if (this.state.contractSelection === 5) {
            if (this.state.onSwitchMintStatus !== 1 || valid.length === 0) {
                return;
            }
        }
        else if (this.state.contractSelection === 6) {
            if (this.state.crudelingsMintStatus !== 1 || valid.length === 0) {
                return;
            }
        }
        else if (this.state.contractSelection === 7) {
            if (this.state.second5MintStatus !== 1 || valid.length === 0) {
                return;
            }
        }

        const toMint = valid.slice(0, 100);
        // const toMint = valid.slice(0, 2);

        this.playLoadingMap();

        this.setState({
            isConfirmingMint: true,
        }, () => {
            (() => {
                if (this.state.contractSelection === 1) {
                    return (this.state.cbjContract.dontGoAlone(toMint));
                }
                else if (this.state.contractSelection === 2) {
                    return (this.state.bottleContract.piktItUpp(toMint));
                }
                else if (this.state.contractSelection === 3) {
                    return (this.state.first5Contract.groseriRun(toMint));
                }
                else if (this.state.contractSelection === 4) {
                    return (this.state.offSwitchContract.switchySwitch(toMint));
                }
                else if (this.state.contractSelection === 5) {
                    return (this.state.onSwitchContract.switchySwitch(toMint));
                }
                else if (this.state.contractSelection === 6) {
                    return (this.state.crudelingsContract.HCBD(toMint));
                }
                else if (this.state.contractSelection === 7) {
                    return (this.state.second5Contract.pikanikBaskitz(toMint));
                }
            })()
                .then((res) => {
                    return res.wait();
                })
                .then(async () => {
                    this.setState({
                        isConfirmingMint: false,
                    }, async () => {
                        this.stopLoadingMap();
                        await this.loadEggs();
                    });
                })
                .catch((err) => {
                    console.log(err);

                    alert('The transmission failed to reach the other side... Please try again... You are CrudeBorne... We are CrudeBorne...');

                    try {
                        const errJSON = JSON.parse(
                            err.toString()
                                .split('error=')[1]
                                .split(', method')[0]
                        );

                        console.log(errJSON);
                    }
                    catch (errInner) {
                        console.log(errInner);
                    }

                    this.setState({
                        isConfirmingMint: false,
                    }, () => {
                        this.stopLoadingMap();
                    });
                });
        });
    }

    getMintIndex = () => {
        // console.log('GET MINT INDEX');
        if (this.state.contractSelection === 0) {
            return 0;
        }

        // if (this.state.mintIndexMap[this.state.contractSelection] === 2) {
        //     return 2;
        // }

        let mintIndex = (() => {
            if (this.state.contractSelection === 1) {
                return this.state.jellyMintStatus;
            }
            else if (this.state.contractSelection === 2) {
                return this.state.bottleMintStatus;
            }
            else if (this.state.contractSelection === 3) {
                return this.state.first5MintStatus;
            }
            else if (this.state.contractSelection === 4) {
                return this.state.offSwitchMintStatus;
            }
            else if (this.state.contractSelection === 5) {
                return this.state.onSwitchMintStatus;
            }
            else if (this.state.contractSelection === 6) {
                return this.state.crudelingsMintStatus;
            }
            else if (this.state.contractSelection === 7) {
                return this.state.second5MintStatus;
            }
        })();

        // if (this.state.jellyMintStatus !== 0) {
        if (mintIndex !== 0) {
            if (this.state.eggsLoading) {
                // if (this.state.jellyMintStatus === 2) {
                if (mintIndex === 2) {
                    mintIndex = 2;
                }
                else {
                    mintIndex = 0;
                }
            }
            else {
                // if (this.computeValid().length === 0 || this.state.jellyMintStatus === 2) {
                if (this.computeValid().length === 0 || mintIndex === 2) {
                    mintIndex = 2;
                    // console.log(this.computeValid());
                }
            }
        }

        // if (mintIndex === 2) {
        //     this.setState((state) => {
        //         return ({
        //             mintIndexMap: {
        //                 ...state.mintIndexMap,
        //                 [this.state.contractSelection]: 2,
        //             }
        //         });
        //     });
        // }

        return mintIndex;
    }

    setSelectedContract = (selectedContract, callback) => {
        this.setState({
            contractSelection: selectedContract,
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    selectJellyContract = () => {
        this.setSelectedContract(1, this.closeContractSelector);
    }

    selectElixirContract = () => {
        this.setSelectedContract(2, this.closeContractSelector);
    }

    selectFirst5Contract = () => {
        this.setSelectedContract(3, this.closeContractSelector);
    }

    selectOffSwitchContract = () => {
        this.setSelectedContract(4, this.closeContractSelector);
    }

    selectOnSwitchContract = () => {
        this.setSelectedContract(5, this.closeContractSelector);
    }

    selectCrudeLingsContract = () => {
        this.setSelectedContract(6, this.closeContractSelector);
    }

    selectSecond5Contract = () => {
        this.setSelectedContract(7, this.closeContractSelector);
    }

    clearContractSelection = () => {
        this.setSelectedContract(0, this.closeContractSelector);
    }

    openContractSelector = () => {
        this.setState({
            contractSelectorOpen: true,
        });
    }

    closeContractSelector = () => {
        this.setState({
            contractSelectorOpen: false,
        });
    }

    handleCrystalBallClick = () => {
        if (this.state.contractSelection === 0) {
            if (this.state.contractSelectorOpen) {
                this.closeContractSelector();
            }
            else {
                this.openContractSelector();
            }
        }
        else {
            this.openMintPopup();
        }
    }

    // openMintPopup = () => {
    //     this.setState({
    //         showPopup: true,
    //     }, () => {
    //         this.setState({
    //             popupOpacity: 1,
    //         });
    //     });
    // }
    //
    // closeMintPopup = (e) => {
    //     if (e.target !== e.currentTarget) {
    //         return;
    //     }
    //
    //     this.setState({
    //         popupOpacity: 0,
    //     }, () => {
    //         setTimeout(() => {
    //             this.setState({
    //                 showPopup: false,
    //             }, () => {
    //                 this.clearContractSelection();
    //             });
    //         }, 500);
    //     });
    // }

    // openMap = () => {
    //     this.setState({
    //         isMapOpen: true,
    //     }, () => {
    //         this.playHQMap();
    //     });
    // }

    openMap = () => {
        this.setState({
            isMapOpen: true,
        }, () => {
            this.setState({
                mapPopupOpacity: 1,
            }, () => {
                this.playHQMap();
            });
        });
    }

    closeMap = (e) => {
        if (e.target !== e.currentTarget) {
            return;
        }

        this.setState({
            mapPopupOpacity: 0,
        },() => {
            setTimeout(() => {
                this.setState({
                    isMapOpen: false,
                }, () => {
                    this.stopHQMap();
                });
            }, 500);
        });
    }

    showConnectButton = () => {
        const audioElement = document.getElementById('backgroundAudio');
        audioElement.pause();
        audioElement.currentTime = 0;
        audioElement.play();
        this.setState({
            connectShowing: true,
        });
    }

    setPathByEggTalk = () => {
        this.setState((state) => {
            let pathToSet;

            const eggsTalkedTo = eggTalk[state.selectedAddress.toLowerCase()];
            console.log(`EggsBalance: ${state.eggsBalance}`)
            console.log(state.selectedAddress.toLowerCase());
            console.log(eggsTalkedTo);
            if (eggsTalkedTo === undefined) {
                return ({
                    pathByEggTalk: 2
                });
            }

            if (state.eggsBalance === 0) {
                return ({
                    pathByEggTalk: 2
                });
            }

            // console.log(state.eggsBalance);

            if (eggsTalkedTo >= Math.floor(state.eggsBalance*0.5)) {
            // if (eggsTalkedTo >= 1) {
                console.log('Sunny')
                pathToSet = 1;
            }
            else {
                console.log('Cave')
                pathToSet = 2;
            }

            return ({
                pathByEggTalk: pathToSet
            });
        }, () => {
            this.continueToMain();
        });
    }

    render() {
        return (<div className={'Home ' + (isMobile(navigator) ? 'Mobile' : 'Desktop')}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    minHeight: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    zIndex: '-1',
                }}
                dangerouslySetInnerHTML={{
                    __html: (() => {
                            let toReturn;

                            if (this.state.pathByEggTalk === 0) {
                                toReturn = `<video id="backgroundVideo" loop muted playsinline webkit-playsinline="true" oncanplay="${this.allowContinueToMain}" src="${backgroundVideo}"/>`
                            }
                            else if (this.state.pathByEggTalk === 1) {
                                toReturn = `<video id="backgroundVideo" loop muted playsinline webkit-playsinline="true" oncanplay="${this.allowContinueToMain}" src="${sunnyVideo}"/>`
                            }
                            else {
                                toReturn = toReturn = `<video id="backgroundVideo" loop muted playsinline webkit-playsinline="true" oncanplay="${this.allowContinueToMain}" src="${caveVideo}"/>`
                            }

                            return toReturn
                        })() +
                        (() => {
                            let toReturn;

                            if (this.state.pathByEggTalk === 0) {
                                toReturn = `<audio id="backgroundAudio" webkit-playsinline="true" loop oncanplay="${this.allowContinueToMain}" src="${siteAudio}" style="display: none"/>`;
                            }
                            else if (this.state.pathByEggTalk === 1) {
                                toReturn = `<audio id="backgroundAudio" webkit-playsinline="true" autoplay="true" loop oncanplay="${this.allowContinueToMain}" src="${sunnyAudio}" style="display: none"/>`;
                            }
                            else {
                                toReturn = `<audio id="backgroundAudio" webkit-playsinline="true" autoplay="true" loop oncanplay="${this.allowContinueToMain}" src="${caveAudio}" style="display: none"/>`;
                            }

                            return toReturn;
                        })()
                }}
            />
            {/*>*/}
            {/*    <video*/}
            {/*        id={'backgroundVideo'}*/}
            {/*        autoPlay={false}*/}
            {/*        muted={true}*/}
            {/*        loop={true}*/}
            {/*        onCanPlay={this.allowContinueToMain}*/}
            {/*        playsInline={true}*/}
            {/*        controls={false}*/}
            {/*    >*/}
            {/*        <source src={backgroundVideo} type={'video/mp4'}/>*/}
            {/*    </video>*/}
            {/*    <audio*/}
            {/*        id={'backgroundAudio'}*/}
            {/*        controls={false}*/}
            {/*        autoPlay={false}*/}
            {/*        muted={false}*/}
            {/*        playsInline={true}*/}
            {/*        loop={true}*/}
            {/*        onCanPlay={this.allowContinueToMain}*/}
            {/*        style={{*/}
            {/*            display: 'none',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <source src={siteAudio} type="audio/mpeg"/>*/}
            {/*    </audio>*/}
            {/*</div>*/}
            <div
                className={'EntryTextContainer'}
                style={{
                    pointerEvents: this.state.entryBeingAnimated ? 'auto' : 'none',
                    zIndex: this.state.entryBeingAnimated ? '100' : '-100',
                    backgroundColor: this.state.etcBgOpacity === 1 ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)',
                }}
            >
                <div className={'EntryTextMain'}>
                    <div
                        className={'ETMInner'}
                        style={{
                            animation: this.state.entryBeingAnimated ? 'animate-entry-text 5s infinite linear' : 'none',
                            visibility: this.state.entryBeingAnimated ? 'visible' : 'hidden'
                        }}
                    >
                        {entryTextSequence[this.state.entryTextIndex]}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '40%',
                            minHeight: '40%',
                        }}
                    />
                    <div
                        className={'ETMInner'}
                        style={{
                            opacity: '0',
                        }}
                    >
                        {entryTextSequence[this.state.entryTextIndex]}
                    </div>
                </div>
                <div className={'EntryTextAfter'}>
                    <div
                        className={'ETAInner'}
                        style={{
                            animation: this.state.entryAfterBeingAnimated ? 'animate-entry-text 5s infinite linear' : 'none',
                            visibility: this.state.entryAfterBeingAnimated ? 'visible' : 'hidden'
                        }}
                    >
                        {entryAfterTextSequence[this.state.entryAfterTextIndex]}
                    </div>
                </div>
            </div>
            <div className={'MainContainer'}>
                <div className={'TopRow'}>
                    <div className={'MintButtonContainer'}>
                        <img
                            src={(() => {
                                if (this.state.contractSelection === 0) {
                                    return crystalBall;
                                }
                                else if (this.state.contractSelection === 1) {
                                    return crystalBallPurple;
                                }
                                else if (this.state.contractSelection === 2) {
                                    return crystalBallGreen;
                                }
                                else if (this.state.contractSelection === 3) {
                                    return crystalBallWhite;
                                }
                                else if (this.state.contractSelection === 4) {
                                    return crystalBallOff;
                                }
                                else if (this.state.contractSelection === 5) {
                                    return crystalBallOn;
                                }
                                else if (this.state.contractSelection === 6) {
                                    return crystalBallCrudeLings;
                                }
                                else {
                                    return crystalBallAqua;
                                }
                            })()}
                            alt={'Click here to mint!'}
                            onClick={this.handleCrystalBallClick}
                        />
                    </div>
                    <div className={'MintButtonContainer'}>
                        <img
                            src={compassPlain}
                            alt={'Click here to mint!'}
                            onClick={this.openMap}
                        />
                    </div>
                    {/*<div*/}
                    {/*    className={'SocialButton'}*/}
                    {/*>*/}
                    {/*    <a*/}
                    {/*        href={'https://twitter.com/crudeborne'}*/}
                    {/*        target={'_blank'}*/}
                    {/*        rel={'nofollow noreferrer'}*/}
                    {/*    >*/}
                    {/*        <img*/}
                    {/*            src={twitterButton}*/}
                    {/*            alt={'CrudeBorne Twitter'}*/}
                    {/*        />*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*    className={'SocialButton'}*/}
                    {/*    style={{*/}
                    {/*        height: '116%',*/}
                    {/*        minHeight: '116%',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <a*/}
                    {/*        href={'https://opensea.io/collection/crudeborne-jelly-babies'}*/}
                    {/*        target={'_blank'}*/}
                    {/*        rel={'nofollow noreferrer'}*/}
                    {/*    >*/}
                    {/*        <img*/}
                    {/*            src={openseaButton}*/}
                    {/*            alt={'CrudeBorne Jelly Babies OpenSea'}*/}
                    {/*        />*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
                <div
                    className={'ContractSelectRow ' + (
                        this.state.contractSelectorOpen ? 'Show' : 'Hide'
                    )}
                    style={{
                        pointerEvents: this.state.contractSelectorOpen ? 'auto' : 'none'
                    }}
                >
                    <div className={'CSRInner'}>
                        <div className={'GlowOrb'}>
                            <img
                                src={aquaGlowBall}
                                alt={'Aquamarine Glow'}
                                onClick={this.selectSecond5Contract}
                            />
                        </div>
                        <div
                            style={{
                                width: '10%',
                                height: '100%',
                                minHeight: '100%',
                                opacity: '0',
                                pointerEvents: 'none',
                            }}
                        />
                        <div className={'GlowOrb'}>
                            <img
                                src={crudelingsGlowBall}
                                alt={'CrudeLings Glow'}
                                onClick={this.selectCrudeLingsContract}
                            />
                        </div>
                        <div
                            style={{
                                width: '10%',
                                height: '100%',
                                minHeight: '100%',
                                opacity: '0',
                                pointerEvents: 'none',
                            }}
                        />
                        <div className={'GlowOrb'}>
                            <img
                                src={offGlowBall}
                                alt={'Off/Night Glow'}
                                onClick={this.selectOffSwitchContract}
                            />
                        </div>
                        <div
                            style={{
                                width: '10%',
                                height: '100%',
                                minHeight: '100%',
                                opacity: '0',
                                pointerEvents: 'none',
                            }}
                        />
                        <div className={'GlowOrb'}>
                            <img
                                src={onGlowBall}
                                alt={'On/Day Glow'}
                                onClick={this.selectOnSwitchContract}
                            />
                        </div>
                        <div
                            style={{
                                width: '10%',
                                height: '100%',
                                minHeight: '100%',
                                opacity: '0',
                                pointerEvents: 'none',
                            }}
                        />
                        <div className={'GlowOrb'}>
                            <img
                                src={whiteGlowBall}
                                alt={'Gray-White Glow'}
                                onClick={this.selectFirst5Contract}
                            />
                        </div>
                        <div
                            style={{
                                width: '10%',
                                height: '100%',
                                minHeight: '100%',
                                opacity: '0',
                                pointerEvents: 'none',
                            }}
                        />
                        <div className={'GlowOrb'}>
                            <img
                                src={greenGlowBall}
                                alt={'Light Green Glow'}
                                onClick={this.selectElixirContract}
                            />
                        </div>
                        <div
                            style={{
                                width: '10%',
                                height: '100%',
                                minHeight: '100%',
                                opacity: '0',
                                pointerEvents: 'none',
                            }}
                        />
                        <div className={'GlowOrb'}>
                            <img
                                src={purpleGlowBall}
                                alt={'Light Purple Glow'}
                                onClick={this.selectJellyContract}
                            />
                        </div>
                    </div>
                </div>
                {/*<div className={'BottomRow'}>*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            width: '75%',*/}
                {/*            height: '100%',*/}
                {/*            minHeight: '100%',*/}
                {/*            pointerEvents: 'none',*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <div*/}
                {/*        className={'SocialButton'}*/}
                {/*        // style={{*/}
                {/*        //     width: '25vw',*/}
                {/*        //     maxWidth: '25vw',*/}
                {/*        //     height: '100%',*/}
                {/*        //     minHeight: '100%',*/}
                {/*        //     objectFit: 'contain',*/}
                {/*        // }}*/}
                {/*    >*/}
                {/*        <a*/}
                {/*            href={`https://${DEPLOY_MODE === 'dev' ? 'rinkeby.' : ''}etherscan.io/address/` + JELLY_ADDRESS}*/}
                {/*            target={'_blank'}*/}
                {/*            rel={'nofollow noreferrer'}*/}
                {/*        >*/}
                {/*            <img*/}
                {/*                src={etherscanButton}*/}
                {/*                alt={'CrudeBorne Jelly Babies - Verified Contract on Etherscan'}*/}
                {/*            />*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={'RightColumn'}>
                    <div className={'SocialLinks'}>
                        <a
                            href={`https://${DEPLOY_MODE === 'dev' ? 'goerli.' : ''}etherscan.io/address/` + SECOND_5_ADDRESS}
                            target={'_blank'}
                            rel={'nofollow noreferrer'}
                        >
                            <img
                                src={etherscanNew}
                                alt={'Etherscan'}
                            />
                        </a>
                        <a
                            href={'https://twitter.com/crudeborne'}
                            target={'_blank'}
                            rel={'nofollow noreferrer'}
                        >
                            <img
                                src={twitterNew}
                                alt={'Twitter'}
                            />
                        </a>
                        <a
                            href={'https://opensea.io/collection/crudeborne-elixir'}
                            target={'_blank'}
                            rel={'nofollow noreferrer'}
                        >
                            <img
                                src={openSeaNew}
                                alt={'OpenSea'}
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div
                className={'MintPopupContainer'}
                style={{
                    opacity: `${this.state.popupOpacity}`,
                    zIndex: this.state.showPopup ? '1' : '-2',
                }}
                onClick={this.closeMintPopup}
            >
                <div
                    className={'MPCInner'}
                    onClick={this.closeMintPopup}
                >
                    {
                        (() => {
                            if (
                                this.state.selectedAddress === null ||
                                this.state.selectedAddress === undefined
                            ) {
                                return (<div className={'MintPopup'}>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '15vh',
                                            minHeight: '15vh',
                                        }}
                                    />
                                    <div className={'CWPreText'}>
                                        {'???'}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            minHeight: '40px',
                                        }}
                                    />
                                    {(() => {
                                        if (this.state.selectedAddress === null) {
                                            return (<div className={'CWContainer'}>
                                                <div
                                                    className={'ConnectWallet'}
                                                    onClick={this.connectWallet}
                                                >
                                                    {'Connect Wallet'}
                                                </div>
                                            </div>);
                                        }
                                    })()}
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            minHeight: '40px',
                                        }}
                                    />
                                    <div className={'CWPreText'}>
                                        {'¿¿¿'}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            minHeight: '100%',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            zIndex: '1',
                                            boxShadow: 'inset 0 0 20px 0 #111111',
                                            pointerEvents: 'none',
                                            borderRadius: 'inherit',
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '15vh',
                                            minHeight: '15vh',
                                        }}
                                    />
                                </div>);
                            }
                            else {
                                return (<div className={'MintPopup'}>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            minHeight: '100%',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            bottom: '0',
                                            right: '0',
                                            zIndex: '10',
                                            boxShadow: 'inset 0 0 20px 0 #111111',
                                            pointerEvents: 'none',
                                            borderRadius: 'inherit',
                                        }}
                                    />
                                    <div
                                        className={'MPMintProcessing ' + (this.state.isConfirmingMint ? 'Show' : 'Hide')}
                                        style={{

                                        }}
                                    >
                                        {/*<div className={'MPMPMap'}>*/}
                                        {/*    <video*/}
                                        {/*        id={'inProgressVideo'}*/}
                                        {/*        autoPlay={false}*/}
                                        {/*        muted={true}*/}
                                        {/*        loop={true}*/}
                                        {/*        // onLoadedMetadata={this.playVideo}*/}
                                        {/*        // onCanPlay={this.allowContinueToMain}*/}
                                        {/*        playsInline={true}*/}
                                        {/*        controls={false}*/}
                                        {/*    >*/}
                                        {/*        <source src={mintProcessingMap} type={'video/mp4'}/>*/}
                                        {/*    </video>*/}
                                        {/*</div>*/}
                                        <div
                                            className={'MPMPMap'}
                                            dangerouslySetInnerHTML={{
                                                __html: `<video id="inProgressVideo" loop muted playsinline webkit-playsinline="true" src="${mintProcessingMap}"/>`
                                            }}
                                        />
                                    </div>
                                    {/*<div className={'MPTitle'}>*/}
                                    {/*    <img*/}
                                    {/*        src={mintButton}*/}
                                    {/*        alt={'Mint CrudeBorne Eggs!'}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className={'MPInner'}>
                                        {/*<div*/}
                                        {/*    style={{*/}
                                        {/*        width: '100%',*/}
                                        {/*        height: '15vh',*/}
                                        {/*        minHeight: '15vh',*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <div
                                            style={{
                                                width: '100%',
                                                height: isMobile(navigator) ? '7vw' : '24px',
                                                maxHeight: isMobile(navigator) ? '7vw' : '24px',
                                                position: 'relative',
                                            }}
                                        >
                                            <div className={'MPWalletAddress'}>
                                                {
                                                    this.state.selectedAddress.slice(0,10) + '...' +
                                                    this.state.selectedAddress.slice(
                                                        this.state.selectedAddress.length - 8
                                                    )
                                                }
                                            </div>
                                            <div
                                                className={'MPWalletAddress'}
                                                style={{
                                                    position: 'relative',
                                                    top: isMobile(navigator) ? '-7vw' : '-24px',
                                                    left: '0',
                                                }}
                                            >
                                                {
                                                    this.state.selectedAddress.slice(0,10) + '...' +
                                                    this.state.selectedAddress.slice(
                                                        this.state.selectedAddress.length - 8
                                                    )
                                                }
                                            </div>
                                            <div
                                                className={'MPWalletAddress'}
                                                style={{
                                                    position: 'relative',
                                                    top: isMobile(navigator) ? '-14vw' : '-48px',
                                                    left: '0',
                                                }}
                                            >
                                                {
                                                    this.state.selectedAddress.slice(0,10) + '...' +
                                                    this.state.selectedAddress.slice(
                                                        this.state.selectedAddress.length - 8
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className={'MPDescription'}>
                                            {
                                                // this.state.contractSelection === 1 ? (
                                                //     'It is dangerous to go alone.'
                                                // ) : (
                                                //     this.state.contractSelection === 2 ? (
                                                //         'It\'s hard to see in the dark...'
                                                //     ) : (
                                                //         'Many Paths. One Destination.'
                                                //     )
                                                // )
                                                (() => {
                                                    const phrases = [
                                                        'It is dangerous to go alone.',
                                                        'It\'s hard to see in the dark...',
                                                        'Many Paths. One Destination.',
                                                        '... Have you tried turning it off? ...',
                                                        '... and back on again? ...',
                                                        'oh you gun and dun it Nao',
                                                        'What a lovalee picanick beskit',
                                                    ];

                                                    return phrases[this.state.contractSelection - 1];
                                                })()
                                            }
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '4px',
                                                    minHeight: '4px',
                                                }}
                                            />
                                            {
                                                // this.state.contractSelection === 1 ? (
                                                //     'Here. Take this.'
                                                // ) : (
                                                //     ''
                                                // )
                                                (() => {
                                                    const phrases = [
                                                        'Here. Take this.',
                                                        '',
                                                        '',
                                                        '',
                                                        '',
                                                        '',
                                                    ];

                                                    return phrases[this.state.contractSelection - 1];
                                                })()
                                            }
                                        </div>
                                        <div
                                            className={'MPMintStatus'}
                                        >
                                            <div
                                                className={'MPMSInner'}
                                                style={{
                                                    pointerEvents: this.getMintIndex() === 1 ? 'auto' : 'none',
                                                    opacity: this.getMintIndex() === 1 ? '0.8' : '0.6',
                                                }}
                                                onClick={(() => {
                                                    if (this.getMintIndex() === 1) {
                                                        // console.log('YEET');
                                                        return this.retrieve;
                                                    }
                                                    else {
                                                        // console.log('REEE');
                                                        return (() => {});
                                                    }
                                                })()}
                                            >
                                                {(() => {
                                                    const msTextOptions = [
                                                        // `waitaminnit.${(() => {
                                                        //     console.log(`NUM DOTS IS [[[ ${this.state.numDots} ]]]`)
                                                        //     if (this.state.numDots === 0) {
                                                        //         return '';
                                                        //     }
                                                        //     else if (this.state.numDots === 1) {
                                                        //         return '.';
                                                        //     }
                                                        //     else {
                                                        //         return '..';
                                                        //     }
                                                        // })()}`,
                                                        this.state.waitingText,
                                                        (this.state.contractSelection === 7) ? 'yukeneetit.' : 'stillsumleft.',
                                                        (this.state.contractSelection === 7) ? 'baskitempti.' : 'nomoreforu.',
                                                    ];

                                                    // let mintIndex = this.state.jellyMintStatus;
                                                    //
                                                    // if (this.state.jellyMintStatus !== 0) {
                                                    //     if (this.state.eggsLoading) {
                                                    //         mintIndex = 0;
                                                    //     }
                                                    //     else {
                                                    //         if (this.computeValid().length === 0 || this.state.jellyMintStatus === 2) {
                                                    //             mintIndex = 2;
                                                    //             console.log(this.computeValid());
                                                    //         }
                                                    //     }
                                                    // }

                                                    // return msTextOptions[this.state.jellyMintStatus];
                                                    return msTextOptions[this.getMintIndex()];
                                                })()}
                                            </div>
                                        </div>
                                        {/*<div className={'MPMintCount'}>*/}
                                        {/*    {`${this.state.jellyTotalSupply} ${this.state.jellyTotalSupply === 1 ? 'has' : 'have'} found a companion...`}*/}
                                        {/*</div>*/}
                                        <div className={'MPDescription'}>
                                            {
                                                // this.state.contractSelection === 1 ? (
                                                //     'We are CrudeBorne'
                                                // ) : (
                                                //     ''
                                                // )
                                                (() => {
                                                    const phrases = [
                                                        'We are CrudeBorne',
                                                        '',
                                                        '',
                                                        '',
                                                        '',
                                                        '',
                                                    ];

                                                    return phrases[this.state.contractSelection - 1];
                                                })()
                                            }
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '4px',
                                                    minHeight: '4px',
                                                }}
                                            />
                                            {
                                                // this.state.contractSelection === 1 ? (
                                                //     'You are CrudeBorne'
                                                // ) : (
                                                //     this.state.contractSelection === 2 ? (
                                                //         'Wots dis???'
                                                //     ) : (
                                                //         'And. Here. We... Goes...'
                                                //     )
                                                // )
                                                (() => {
                                                    const phrases = [
                                                        'You are CrudeBorne',
                                                        'Wots dis???',
                                                        'And. Here. We... Goes...',
                                                        '... Flick ...',
                                                        '... Click ...',
                                                        'watch out!',
                                                        'Happy holidays farkers',
                                                    ];

                                                    return phrases[this.state.contractSelection - 1];
                                                })()
                                            }
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: isMobile(navigator) ? '7vw' : '24px',
                                                maxHeight: isMobile(navigator) ? '7vw' : '24px',
                                                position: 'relative',
                                            }}
                                        >
                                            <div className={'MPWalletAddress'}>
                                                {
                                                    (() => {
                                                        // const tsNumber = this.state.contractSelection === 1 ?
                                                        //     this.state.jellyTotalSupply : (
                                                        //         this.state.contractSelection === 2 ?
                                                        //             this.state.bottleTotalSupply :
                                                        //             this.state.first5TotalSupply
                                                        //     );

                                                        const tsNumberOptions = [
                                                            this.state.jellyTotalSupply,
                                                            this.state.bottleTotalSupply,
                                                            this.state.first5TotalSupply,
                                                            this.state.offSwitchTotalSupply,
                                                            this.state.onSwitchTotalSupply,
                                                            this.state.crudelingsTotalSupply,
                                                            this.state.second5TotalSupply,
                                                        ];

                                                        const tsNumber = tsNumberOptions[this.state.contractSelection - 1];

                                                        const contractClaimText = [
                                                            'crossed',
                                                            'been found',
                                                            'yeeted',
                                                            'been switched',
                                                            'been clicked',
                                                            'flinged',
                                                            'bin eatunz'
                                                        ];

                                                        // return (
                                                        //     `${tsNumber} ${tsNumber === 1 ? 'has' : 'have'} ${this.state.contractSelection === 1 ? 'crossed' : 'been found'}`
                                                        // );
                                                        if (this.state.contractSelection === 6) {
                                                            return (`${tsNumber} ${tsNumber === 1 ? 'ling' : 'lings'} ${tsNumber === 1 ? 'has' : 'have'} ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                        else if (this.state.contractSelection === 7) {
                                                            return (`${tsNumber} haz ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                        else {
                                                            return (`${tsNumber} ${tsNumber === 1 ? 'has' : 'have'} ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                    })()
                                                }
                                            </div>
                                            <div
                                                className={'MPWalletAddress'}
                                                style={{
                                                    position: 'relative',
                                                    top: isMobile(navigator) ? '-7vw' : '-24px',
                                                    left: '0',
                                                }}
                                            >
                                                {
                                                    (() => {
                                                        // const tsNumber = this.state.contractSelection === 1 ?
                                                        //     this.state.jellyTotalSupply : (
                                                        //         this.state.contractSelection === 2 ?
                                                        //             this.state.bottleTotalSupply :
                                                        //             this.state.first5TotalSupply
                                                        //     );

                                                        const tsNumberOptions = [
                                                            this.state.jellyTotalSupply,
                                                            this.state.bottleTotalSupply,
                                                            this.state.first5TotalSupply,
                                                            this.state.offSwitchTotalSupply,
                                                            this.state.onSwitchTotalSupply,
                                                            this.state.crudelingsTotalSupply,
                                                            this.state.second5TotalSupply,
                                                        ];

                                                        const tsNumber = tsNumberOptions[this.state.contractSelection - 1];

                                                        const contractClaimText = [
                                                            'crossed',
                                                            'been found',
                                                            'yeeted',
                                                            'been switched',
                                                            'been clicked',
                                                            'flinged',
                                                            'bin eatunz'
                                                        ];

                                                        // return (
                                                        //     `${tsNumber} ${tsNumber === 1 ? 'has' : 'have'} ${this.state.contractSelection === 1 ? 'crossed' : 'been found'}`
                                                        // );
                                                        if (this.state.contractSelection === 6) {
                                                            return (`${tsNumber} ${tsNumber === 1 ? 'ling' : 'lings'} ${tsNumber === 1 ? 'has' : 'have'} ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                        else if (this.state.contractSelection === 7) {
                                                            return (`${tsNumber} haz ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                        else {
                                                            return (`${tsNumber} ${tsNumber === 1 ? 'has' : 'have'} ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                    })()
                                                }
                                            </div>
                                            <div
                                                className={'MPWalletAddress'}
                                                style={{
                                                    position: 'relative',
                                                    top: isMobile(navigator) ? '-14vw' : '-48px',
                                                    left: '0',
                                                }}
                                            >
                                                {
                                                    (() => {
                                                        // const tsNumber = this.state.contractSelection === 1 ?
                                                        //     this.state.jellyTotalSupply : (
                                                        //         this.state.contractSelection === 2 ?
                                                        //             this.state.bottleTotalSupply :
                                                        //             this.state.first5TotalSupply
                                                        //     );

                                                        const tsNumberOptions = [
                                                            this.state.jellyTotalSupply,
                                                            this.state.bottleTotalSupply,
                                                            this.state.first5TotalSupply,
                                                            this.state.offSwitchTotalSupply,
                                                            this.state.onSwitchTotalSupply,
                                                            this.state.crudelingsTotalSupply,
                                                            this.state.second5TotalSupply,
                                                        ];

                                                        const tsNumber = tsNumberOptions[this.state.contractSelection - 1];

                                                        const contractClaimText = [
                                                            'crossed',
                                                            'been found',
                                                            'yeeted',
                                                            'been switched',
                                                            'been clicked',
                                                            'flinged',
                                                            'bin eatunz'
                                                        ];

                                                        // return (
                                                        //     `${tsNumber} ${tsNumber === 1 ? 'has' : 'have'} ${this.state.contractSelection === 1 ? 'crossed' : 'been found'}`
                                                        // );
                                                        if (this.state.contractSelection === 6) {
                                                            return (`${tsNumber} ${tsNumber === 1 ? 'ling' : 'lings'} ${tsNumber === 1 ? 'has' : 'have'} ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                        else if (this.state.contractSelection === 7) {
                                                            return (`${tsNumber} haz ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                        else {
                                                            return (`${tsNumber} ${tsNumber === 1 ? 'has' : 'have'} ${contractClaimText[this.state.contractSelection - 1]}`);
                                                        }
                                                    })()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>);
                            }
                        })()
                    }
                </div>
            </div>
            <div
                className={'MintPopupContainer'}
                style={{
                    opacity: `${this.state.mapPopupOpacity}`,
                    zIndex: this.state.isMapOpen ? '1' : '-2',
                }}
                onClick={this.closeMap}
            >
                {/*<div*/}
                {/*    className={'MPCInner'}*/}
                {/*    onClick={this.closeMap}*/}
                {/*>*/}
                {/*    <video*/}
                {/*        id={'mapAnimationHQ'}*/}
                {/*        autoPlay={false}*/}
                {/*        muted={true}*/}
                {/*        loop={true}*/}
                {/*        // onCanPlay={this.allowContinueToMain}*/}
                {/*        playsInline={true}*/}
                {/*        controls={false}*/}
                {/*    >*/}
                {/*        <source src={mapAnimationHQ} type={'video/mp4'}/>*/}
                {/*    </video>*/}
                {/*</div>*/}
                <div
                    className={'MPCInner'}
                    onClick={this.closeMap}
                    dangerouslySetInnerHTML={{
                        __html: `<video id="mapAnimationHQ" loop muted playsinline webkit-playsinline="true" src="${mapAnimationHQ}"/>`
                    }}
                />
            </div>
            <div
                className={'StartingOverlay'}
                style={{
                    // opacity: `${this.state.startingOverlayOpacity}`,
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: this.state.showStartingOverlay ? '1' : '-2',
                }}
            >
                {/*<div*/}
                {/*    className={'SOVideoContainer'}*/}
                {/*    style={{*/}
                {/*        position: 'absolute',*/}
                {/*        top: this.state.startingOverlayOpacity === 1 ? '0' : '-100%',*/}
                {/*        left: this.state.startingOverlayOpacity === 1 ? '0' : '-100%',*/}
                {/*        width: this.state.startingOverlayOpacity === 1 ? '100%' : '300%',*/}
                {/*        height: this.state.startingOverlayOpacity === 1 ? '100%' : '300%',*/}
                {/*        minHeight: this.state.startingOverlayOpacity === 1 ? '100%' : '300%',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <video*/}
                {/*        id={'startingOverlayVideo'}*/}
                {/*        autoPlay={true}*/}
                {/*        muted={true}*/}
                {/*        loop={true}*/}
                {/*        onLoadedMetadata={this.playVideo}*/}
                {/*        playsInline={true}*/}
                {/*        controls={false}*/}
                {/*    >*/}
                {/*        <source src={startingOverlayPortal} type={'video/mp4'}/>*/}
                {/*    </video>*/}
                {/*</div>*/}
                <div
                    className={'SOVideoContainer'}
                    style={{
                        position: 'absolute',
                        top: this.state.startingOverlayOpacity === 1 ? '0' : '-100%',
                        left: this.state.startingOverlayOpacity === 1 ? '0' : '-100%',
                        width: this.state.startingOverlayOpacity === 1 ? '100%' : '300%',
                        height: this.state.startingOverlayOpacity === 1 ? '100%' : '300%',
                        minHeight: this.state.startingOverlayOpacity === 1 ? '100%' : '300%',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: `<video id="startingOverlayVideo" loop muted autoplay playsinline webkit-playsinline="true" src="${startingOverlayPortal}"/>`
                    }}
                />
                <div className={'LACContainer'}>
                    <div className={'LACCInner'}>
                        <div
                            style={isMobile(navigator) ? {
                                width: '100%',
                                minWidth: '100%',
                                height: '25%',
                                minHeight: '25%',
                                // pointerEvents: this.state.canContinueToMain === 2 ? 'auto' : 'none',
                                // cursor: this.state.canContinueToMain === 2 ? 'pointer' : 'default',
                                opacity: 0,
                                userSelect: 'none',
                                fontSize: '25vh',
                                lineHeight: '25vh',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                overflow: 'hidden',
                            } : {
                                width: '100%',
                                minWidth: '100%',
                                height: '25%',
                                minHeight: '25%',
                                pointerEvents: this.state.canContinueToMain === 2 ? 'auto' : 'none',
                                cursor: this.state.canContinueToMain === 2 ? 'pointer' : 'default',
                                opacity: 0,
                                userSelect: 'none',
                                fontSize: '25vh',
                                lineHeight: '25vh',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                overflow: 'hidden',
                            }}
                            onClick={this.showConnectButton}
                        >
                            👁
                        </div>
                    </div>
                </div>
                <div
                    className={'CWContainer'}
                    style={{
                        position: 'absolute',
                        bottom: '10%',
                        left: '0',
                        visibility: (this.state.connectShowing ? 'visible' : 'hidden'),
                        opacity: (this.state.connectShowing ? '1' : '0'),
                    }}
                >
                    <div
                        className={'ConnectWallet'}
                        onClick={this.connectWallet}
                    >
                        {'Connect Wallet'}
                    </div>
                </div>
            </div>
        </div>);
    }
}
