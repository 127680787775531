import './App.css';
import { Route, Routes } from 'react-router';
// import Home from './Components/Home';
import Home from './Components/Home_V4';
// import Story from './Components/Story';

function App() {
  // const provider = new ethers.providers.Web3Provider(window.ethereum)

  return (
      <div className='App'>
        <Routes>
          <Route path={'/'} element={<Home/>}/>
          {/*<Route path={'/story'} element={<Story/>}/>*/}
          {/*<Route path={'/about'} element={<About/>}/>*/}
          {/*<Route path={'/contact'} element={<Contact/>}/>*/}
          {/*<Route path={'/blog'} element={<Blog/>}/>*/}
          {/*<Route path={'/blog/:bp_link_title'} element={<BlogPost/>}/>*/}
        </Routes>
      </div>
  );
}

export default App;



















