module.exports = {
    EGGS_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_EGGS_ADDRESS_DEV :
        process.env.REACT_APP_EGGS_ADDRESS_PROD,
    JELLY_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_JELLY_ADDRESS_DEV :
        process.env.REACT_APP_JELLY_ADDRESS_PROD,
    BOTTLE_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_BOTTLE_ADDRESS_DEV :
        process.env.REACT_APP_BOTTLE_ADDRESS_PROD,
    FIRST_5_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_FIRST_5_ADDRESS_DEV :
        process.env.REACT_APP_FIRST_5_ADDRESS_PROD,
    OFF_SWITCH_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_OFF_SWITCH_ADDRESS_DEV :
        process.env.REACT_APP_OFF_SWITCH_ADDRESS_PROD,
    ON_SWITCH_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_ON_SWITCH_ADDRESS_DEV :
        process.env.REACT_APP_ON_SWITCH_ADDRESS_PROD,
    CRUDELINGS_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_CRUDELINGS_ADDRESS_DEV :
        process.env.REACT_APP_CRUDELINGS_ADDRESS_PROD,
    SECOND_5_ADDRESS: process.env.REACT_APP_DEPLOY_MODE === 'dev' ?
        process.env.REACT_APP_SECOND_5_ADDRESS_DEV :
        process.env.REACT_APP_SECOND_5_ADDRESS_PROD,
    DEPLOY_MODE: process.env.REACT_APP_DEPLOY_MODE,
}
